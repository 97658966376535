import React, { useState } from "react"
import { Form, Button } from "react-bootstrap"
import axios from "axios"
const hostUrl = process.env.GATSBY_BACKEND_HOST

const ContactGeneral = () => {
  const [formState, setFormState] = useState({
    validated: false,
    submitted: false,
    success: false,
    error: "",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      event.stopPropagation()
      setFormState({ ...formState, validated: true })
      axios
        .post(`${hostUrl}/contact-us/send-mail`, {
          firstName: formState.firstName,
          lastName: formState.lastName,
          email: formState.email,
          message: formState.message,
        })
        .then(res => {
          console.log(res)
          setFormState({ ...formState, submitted: true, success: true })
        })
        .catch(err => {
          console.log(err)
          setFormState({ ...formState, submitted: true, error: err })
        })
    }

    setFormState({ ...formState, validated: true })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  return (
    <>
      {/* Contact Section */}
      <section className="contact-page bg-darkened">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Got a question or comment?</h2>
            <h3 className="text-center mb-5">
              Use this form to send it our way.
            </h3>
          </div>
          <div className="row">
            <Form
              className="normal-form"
              noValidate
              validated={formState.validated}
              onSubmit={handleSubmit}
            >
              <div className="two-input-line">
                <Form.Group controlId="formBasicFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formState.firstName}
                    onChange={handleChange}
                    name="firstName"
                    placeholder="First Name"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formState.lastName}
                    onChange={handleChange}
                    name="lastName"
                    placeholder="Last Name"
                    required
                  />
                </Form.Group>
              </div>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={formState.email}
                  onChange={handleChange}
                  name="email"
                  placeholder="Email"
                  required
                />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  value={formState.message}
                  onChange={handleChange}
                  name="message"
                  rows={3}
                  required
                />
              </Form.Group>

              {formState.submitted ? (
                formState.success ? (
                  <p>
                    Thank you! Somebody on our team will be in touch with you
                    shortly.
                  </p>
                ) : (
                  <Button variant="danger">Error</Button>
                )
              ) : (
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              )}
            </Form>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactGeneral
