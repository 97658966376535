import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactGeneral from "../components/forms/contactgeneral"
const hostUrl = process.env.GATSBY_BACKEND_HOST

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" description="" lang="en" meta={[]} />
      <ContactGeneral />
    </Layout>
  )
}

export default ContactPage
